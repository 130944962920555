<template>
	<div v-if="dialogVisible">
		<el-dialog :title="!form.id?'新增部门':'修改部门'" :visible.sync="dialogVisible" width="65%" :before-close="resetform">			
			<div class="addbox">
				<div class="infobox">
					<div class="left_info">
						<div class="item_line">
							<span class="tlename">上级目录：</span>
							<span class="bticon"></span>
							<el-input v-show="isedite" size="small" v-model="fathername" placeholder="" style="flex: 1;" @focus="showselect"></el-input>
							<span v-show="!isedite" class="valuename">{{fathername}}</span>
						</div>
						<div class="item_line">
							<span class="tlename">部门名称：</span>
							<span class="bticon">*</span>
							<el-input v-show="isedite" size="small" v-model="form.name" placeholder="" style="flex: 1;"></el-input>
							<span v-show="!isedite" class="valuename">{{form.name}}</span>
						</div>
						
						<div class="item_line">
							<span class="tlename">排序号：</span>
							<span class="bticon"></span>
							<el-input v-show="isedite" size="small" v-model="form.weigh" placeholder="" style="flex: 1;"></el-input>
							<span v-show="!isedite" class="valuename">{{form.weigh}}</span>
						</div>
						
					</div>
				</div>
			</div>
			<div class="btnbox">
				<el-button size="mini" class="font14" @click="dialogVisible=false">关闭</el-button>
				<el-button v-show="isedite" type="primary" size="mini" class="font14" @click="submit">{{form.id?'保存修改':'保存提交'}}</el-button>				
			</div>
		</el-dialog>
		
		
		<selectfather ref="myselectfather" @selected="selected"></selectfather>
	</div>

</template>

<script>
	import selectfather from './selectfather'
	export default {
		components:{ selectfather },
		data() {
			return {
				dialogVisible: false,
				showdetailbox:true,
				isedite:true,
				fathername:"",
				form:{
					pid:0,
					weigh:"",
					name:""					
				}
			}
		},
		methods: {
			submit(){
				this.$post({
					url: this.form.id?'/api/organizational/edit':'/api/organizational/add',
					params: this.form
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.$parent.loadNode()					
					this.resetform()
				})
			},
			resetform(){
				this.fathername=""
				this.form={
					pid:0,
					weigh:"",
					name:""	
				}
				this.dialogVisible = false
			},
			showselect(){
				this.$refs.myselectfather.isdanxuan = true
				this.$refs.myselectfather.dialogVisible = true
			},
			selected(e){
				this.fathername = e.name
				this.form.pid = e.id
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item_line{
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.tlename{
			width: 120px;
			font-size: 14px;
			
			text-align: right;
			color: #333;
		}
		.bticon{
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}
	.infobox{
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;
		.contbox{
			display: flex;
			padding:20px 40px;
			height: 750px;
			transition: all 0.5s; 
			overflow: hidden;
		}
		.colosecont{
			height: 0;
			padding: 0;
		}
		.title{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}
	.addbox {
		height: 40vh;
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
		
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		padding: 10px 0;
		justify-content: flex-end;
	}

	::v-deep .el-form-item__error{
		padding-top: 0;
	}
	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: '' !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}
</style>