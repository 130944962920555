<template>
	<div v-if="dialogVisible">
		<el-dialog title="选择上级部门" :visible.sync="dialogVisible" width="35%">
			<div class="addbox">
				<el-tree class="filter-tree" :props="props" :load="loadNode" ref="tree" show-checkbox lazy
					:check-strictly="true" @check-change="handleCheckChange">
				</el-tree>
			</div>
			<div class="btnbox">				
				<el-button type="" size="mini" class="font14" @click="dialogVisible = false">取消</el-button>
				<el-button type="primary" size="mini" class="font14" @click="queding">确定</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				filterText: "",
				props: {
					label: 'name',
					children: 'zones',
					isLeaf: 'leaf'
				},
				isdanxuan:false
			}
		},
		created() {
			
		},
		watch: {
			filterText(val) {
				console.log(val)
			}
		},

		methods: {
			queding(){
				this.$emit('selected',this.$refs.tree.getCheckedNodes())
				this.dialogVisible = false
			},
			loadNode(node, resolve) {
				if (node.level === 0) {		
					this.$post({
						url: '/api/organizational/index',
						params: {
							order_by: '',
							sort: '',
							type:1,
							pid:0,
							p: 1,
							size: 9999
						}
					}).then((res) => {
						let list = res.list.map((item)=>{ return {name:item.name,id:item.id,isLeaf:!item.xia}})
						return resolve(list)
					})					
				}else{
					this.$post({
						url: '/api/organizational/index',
						params: {
							order_by: '',
							sort: '',
							type:1,
							pid:node.data.id,
							p: 1,
							size: 9999
						}
					}).then((res) => {
						let list = res.list.map((item)=>{ return {name:item.name,id:item.id,isLeaf:!item.xia}})
						return resolve(list)
					})	
				}
				
				
			},
			handleCheckChange(data, checked, indeterminate) {
				if(this.isdanxuan){					
					this.$emit('selected',data)
					this.dialogVisible = false
				}
			},
			filterNode(value, data) {
				if (!value) return true;
				return data.label.indexOf(value) !== -1;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.tlename {
			width: 120px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		height: 60vh;
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;

	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
		border-top: 1px solid #eee;
	}

	::v-deep .el-form-item__error {
		padding-top: 0;
	}

	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: '' !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}
</style>